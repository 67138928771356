import React, {Suspense, lazy} from "react";
import {Route, Routes, useLocation} from "react-router-dom";

const AKAPages = lazy(() => import("./views/AKAPages"));
const ScrollToTop = lazy(() => import("./components/common/ScrollToTop"));
const Loading = lazy(() => import("./components/common/Loading"));

export default function Routing() {
	// let _path = "/";
	let location = useLocation();
	const {state} = location;
	let urlEndpoint = null;
	if (state) {
		urlEndpoint = state.url;
	} else {
		urlEndpoint = process.env.REACT_APP_API_ENDPOINT + window.location.pathname;
	}

	return (
		<Suspense fallback={<Loading />}>
			<ScrollToTop />
			<Routes>
				<Route
					exact
					path={(`:slug`, "*")}
					element={
						<Suspense
							fallback={
								<>
									<Loading />
								</>
							}
						>
							{state ? <AKAPages slug={state.slug} url={urlEndpoint} /> : <AKAPages />}
						</Suspense>
					}
				/>
			</Routes>
		</Suspense>
	);
}
