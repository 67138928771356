import React, {useEffect} from "react";
import {createRoot} from "react-dom/client";
import {BrowserRouter, useLocation} from "react-router-dom";
import {HelmetProvider} from "react-helmet-async";
import reportWebVitals from "./reportWebVitals";
import AnimatedRoutes from "./components/common/AnimatedRoutes";

const helmetContext = {};
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

function BodyClassController() {
	const location = useLocation();

	useEffect(() => {
		// Remove previously added page- classes
		document.body.classList.forEach((className) => {
			if (className.startsWith("page-")) {
				document.body.classList.remove(className);
			}
		});

		let currentPath = location.pathname;

		// If the current path isn't just "/", remove any trailing slash
		if (currentPath !== "/" && currentPath.endsWith("/")) {
			currentPath = currentPath.slice(0, -1);
		}

		// Determine the class for the current path
		const classToAdd = currentPath === "/" ? "page-home" : `page${currentPath.replace(/\//g, "-")}`;

		document.body.classList.add(classToAdd);
	}, [location]);

	return null;
}

export default function App() {
	return (
		<BrowserRouter>
			<BodyClassController />
			<AnimatedRoutes />
		</BrowserRouter>
	);
}

root.render(
	<HelmetProvider context={helmetContext}>
		<App />
	</HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
